import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';
import server from '@/server';

export type ApplyAirdropProductPayload = {
  prodName: string,
  refAirDropMgtId: number,
  refProductDefId?: number
};

export type ApplyAirdropProductSuccessData = {
  author: string;
  attachId: string;
  castingQty: number;
  couponVOList: Array<{
    couponCount: number;
    couponName: string;
  }>;
  id: number;
  prodName: string;
  tokenId: string;
  tokenIndex: string;
}

export async function applyAirdropProduct(data: APICore.ApplyInfoCreateReq) {
  // server.product(
  //   'POST /apply/saveDirectGet',
  //   data,
  // ).then(oldData => {
  //   console.log(oldData);
  // })
  const res = await server.core("POST /remind/apply/direct-get", data);
  // const list = [...(res.airdropOrderProductVos ?? []), ...(res.airdropOrderCouponVos ?? [])];
  return {
    product: res.airdropOrderProductVos?.[0],
    coupons: res.airdropOrderCouponVos || []
  };
}

import { API_VERSION, SERVER } from '@/services';
import { request } from 'umi';
import { UserInfo } from '@/services/user/getUserById';

export function getByAccountAddress(accountAddress: string) {
  return request<UserInfo>(
    `/${SERVER.user}/${API_VERSION}/users/getByAddress/${accountAddress}`,
    {
      method: 'GET',
      silent: true,
    },
  );
}

export function getByPhone(phone: string) {
  return request<UserInfo>(
    `/${SERVER.user}/${API_VERSION}/users/getByPhone/${phone}`,
    {
      method: 'GET',
      silent: true,
    },
  );
}

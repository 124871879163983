export const API_VERSION = 'api/v1';

export const SERVER_PREFIX = 'rivtrust';
const SERVER_NAMES = [
  'user',
  'message',
  'fileserver',
  'product',
  'hublink',
  'organization',
  'foundation',
  'transaction',
  'register',
  'wallet',
  'log',
  'core',
  'users-core'
] as const;
const SERVER = SERVER_NAMES.reduce((server, name) => {
  server[name] = `${SERVER_PREFIX}-${name}`;
  return server;
}, {} as Record<(typeof SERVER_NAMES)[number], string>);

export { SERVER };

import server from '@/server';

// 商品详情
export async function getProductDetail(productDefId: string) {
  const res = await server.core("POST /productDef/detail-by-id", { id: productDefId });
  if (res?.refPublishMgtId && res?.refPublishMgtId !== '0') {
    const res2 = await server.core("POST /publish/detail-by-id", { id: res.refPublishMgtId });
    if (res2) {
      res2.refPublishMgtId = res2.id;
    }
    return res2;
  }
  return res;
}

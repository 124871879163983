import { request } from "@@/plugin-request/request";
import { API_VERSION, SERVER } from "@/services";
import server from "@/server";

export type WhiteListVerifyPayload = {
  // 应用类型：10，销售上架；20，盲盒活动；30，合成活动；40，空投活动  50，现场活动
  appType: 10 | 20 | 30 | 40 | 50 | "10" | "20" | "30" | "40" | "50";
  productDefId?: number | string;
  srcId?: number | string;
};


const mapAppTypeToVerifyApiPath = {
  // 售卖
  10: "POST /publish/pre-purchase/verify",
  // 盲盒活动
  20: "POST /box-publish-mgt/pre-purchase/verify",
  // 合成
  30: "",
  // 空投
  40: "",
  // 现场活动
  50: "",
}

export function whiteListVerify(data: WhiteListVerifyPayload) { // APICore.PrePurchaseVerifyReq
  const apiPath = mapAppTypeToVerifyApiPath[data.appType]
  if(apiPath) {
    return server.core(apiPath as "POST /publish/pre-purchase/verify", data, { throwIfBizDataIsFalse: false });
  }
  return server.product("POST /appOpt/verify", data, { throwIfBizDataIsFalse: false });
}


const mapAppTypeToNoPreApiPath = {
  // 售卖
  10: "",
  // 盲盒活动
  20: "",
  // 合成
  30: "",
  // 空投
  40: "POST /app-opt/airdrop-verify-no-pre",
  // 现场活动
  50: "",
}
export function whiteListVerifyNoPre(data: WhiteListVerifyPayload) {
  // todo 更换为core接口
  const apiPath = mapAppTypeToNoPreApiPath[data.appType]
  if(apiPath) {
    data.appType = "" + data.appType;
    return server.core(apiPath as "POST /app-opt/airdrop-verify-no-pre", data, { throwIfBizDataIsFalse: false });
  }
  return server.product("POST /appOpt/verifyNoPre", data, { throwIfBizDataIsFalse: false });
}


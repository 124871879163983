import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

// 广场商品列表
export function getProduct(params: any) {
  return request(`/${SERVER.product}/${API_VERSION}/productDef/queryProductDef`, {
    method: 'POST',
    data: params,
    useAuth: false,
  });
}

// 广场精选商品列表
export function getDelicateProducts(params: any) {
  return request<DelicateGoodType[]>(`/${SERVER.product}/${API_VERSION}/recommend/noAuth/mgt/list`, {
    method: 'get',
    params,
  });
}

// 广场商品列表 按filter筛选搜索
export function getProductPage(params: any) {
  return request(`/${SERVER.product}/${API_VERSION}/productDef/noAuth/pageProductDef`, {
    method: 'get',
    params,
    // useAuth: false,
  });
}

// 广场空投列表
// export function getAirdrop(params: any) {
//   return request(`/${SERVER.product}/${API_VERSION}/airDropMgt/noAuth/page`, {
//     method: 'GET',
//     params: {
//       ...params,
//       showDateFlag: true,
//       isAvailable: true,
//       types: [10, 20],
//     },
//     useAuth: false,
//   });
// }

export type DelicateGoodType = {
  avatar: string; // 作者头像
  author: string; // 作者名字
  attachId: string; // 商品封面图
  id: number;
  imOrder: number;
  labelGroup: 10;
  mode: 10 | 20;
  rare: 10 | 20 | 30 | 40;
  prodName: string;
  prodInfo: string;
  srcPublishQty: number;
  refProductDefId: number;
  srcId: number;
  srcSaleQty: number;
  srcStatus: 0 | 1;
  srcType: number;
  price: number;
};

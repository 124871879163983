import {request} from '@@/plugin-request/request';
import {API_VERSION, SERVER} from '@/services';

export function cancelSharingInfo(scanTransferId: number) {
  /** 外部功能疑似被屏蔽，暂时不用管这个接口的更替 */
  return request<boolean>(`/${SERVER.product}/${API_VERSION}/scanTransfer/cancelSharingInfo`, {
    method: 'POST',
    data: {scanTransferId},
  });
}

// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/data/jenkins-node/workspace/rivdap-rivdap-nft-fe/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'@/pages/login'), loading: LoadingComponent}),
    "title": "登录",
    "exact": true
  },
  {
    "path": "/register",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register' */'@/pages/register'), loading: LoadingComponent}),
    "title": "创建账号",
    "exact": true
  },
  {
    "path": "/register/:id",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register' */'@/pages/register'), loading: LoadingComponent}),
    "title": "创建账号",
    "exact": true
  },
  {
    "path": "/reset-password",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resetPassword' */'@/pages/resetPassword'), loading: LoadingComponent}),
    "title": "重置密码",
    "exact": true
  },
  {
    "exact": false,
    "path": "/",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/ForceLogin'), loading: LoadingComponent})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "redirect": "/index",
        "exact": true
      },
      {
        "path": "/pay",
        "routes": [
          {
            "path": "/pay/createOrder",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pay__createOrder' */'@/pages/pay/createOrder'), loading: LoadingComponent}),
            "title": "创建订单",
            "exact": true
          },
          {
            "path": "/pay/to-pay",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pay__toPay' */'@/pages/pay/toPay'), loading: LoadingComponent}),
            "title": "订单支付",
            "exact": true
          },
          {
            "path": "/pay/result",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pay__result' */'@/pages/pay/result'), loading: LoadingComponent}),
            "title": "支付结果",
            "exact": true
          },
          {
            "path": "/pay/result/error",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pay__result__error' */'@/pages/pay/result/error'), loading: LoadingComponent}),
            "title": "支付结果",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ResultPage__404' */'@/components/ResultPage/404'), loading: LoadingComponent}),
            "title": "404",
            "exact": true
          }
        ]
      },
      {
        "path": "/change-password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__changePassword' */'@/pages/changePassword'), loading: LoadingComponent}),
        "title": "修改密码",
        "exact": true
      },
      {
        "path": "/rules",
        "routes": [
          {
            "path": "/rules",
            "redirect": "/rules/list",
            "exact": true
          },
          {
            "path": "/rules/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rules__index' */'@/pages/rules/index.tsx'), loading: LoadingComponent}),
            "title": "RivDap规则",
            "exact": true
          },
          {
            "path": "/rules/files/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rules__files' */'@/pages/rules/files'), loading: LoadingComponent}),
            "title": "RivDap规则",
            "exact": true
          }
        ]
      },
      {
        "path": "/index",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index-2.0' */'@/pages/index-2.0'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/market",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marketPage-2.0' */'@/pages/marketPage-2.0'), loading: LoadingComponent}),
        "title": "",
        "exact": true
      },
      {
        "path": "/events",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activityList' */'@/pages/activityList'), loading: LoadingComponent}),
        "title": "",
        "exact": true
      },
      {
        "path": "/noInLive",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ResultPage__noInLive' */'@/components/ResultPage/noInLive'), loading: LoadingComponent}),
        "title": "缺省页",
        "exact": true
      },
      {
        "path": "/events/noInLive",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ResultPage__eventsNoInLive' */'@/components/ResultPage/eventsNoInLive'), loading: LoadingComponent}),
        "title": "缺省页",
        "exact": true
      },
      {
        "path": "/composition/details/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details-2.0__composition' */'@/pages/details-2.0/composition'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/shelfChecker/compound'), loading: LoadingComponent})],
        "title": "合成详情",
        "exact": true
      },
      {
        "path": "/goods",
        "routes": [
          {
            "path": "/goods/details/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details-2.0__market' */'@/pages/details-2.0/market'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/shelfChecker/product'), loading: LoadingComponent})],
            "title": "商品详情",
            "exact": true
          },
          {
            "path": "/goods/details/my/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection' */'@/pages/collection'), loading: LoadingComponent}),
            "title": "我的资产详情",
            "exact": true
          },
          {
            "path": "/goods/rightsDetail/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details-2.0__goodsRights' */'@/pages/details-2.0/goodsRights'), loading: LoadingComponent}),
            "title": "权益详情",
            "exact": true
          },
          {
            "path": "/goods/rightsDetail/my/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details-2.0__goodsRights__my' */'@/pages/details-2.0/goodsRights/my'), loading: LoadingComponent}),
            "title": "权益详情",
            "exact": true
          },
          {
            "path": "/goods/rightsDetail/assets/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details-2.0__goodsRights__assets' */'@/pages/details-2.0/goodsRights/assets'), loading: LoadingComponent}),
            "title": "权益详情",
            "exact": true
          },
          {
            "path": "/goods/rightsDetail/assets/transferRecord/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__equityTransferRecord' */'@/pages/equityTransferRecord'), loading: LoadingComponent}),
            "title": "转赠记录",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ResultPage__404' */'@/components/ResultPage/404'), loading: LoadingComponent}),
            "title": "404",
            "exact": true
          }
        ]
      },
      {
        "path": "/airdrop",
        "routes": [
          {
            "path": "/airdrop/details/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details-2.0__airdrop' */'@/pages/details-2.0/airdrop'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/shelfChecker/airdrop'), loading: LoadingComponent})],
            "title": "空投详情",
            "exact": true
          },
          {
            "path": "/airdrop/details/invitation/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details-2.0__airdrop__invitation' */'@/pages/details-2.0/airdrop/invitation'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/shelfChecker/airdropInvitation'), loading: LoadingComponent})],
            "title": "空投详情",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ResultPage__404' */'@/components/ResultPage/404'), loading: LoadingComponent}),
            "title": "404",
            "exact": true
          }
        ]
      },
      {
        "path": "/scene",
        "routes": [
          {
            "path": "/scene/details/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details-2.0__scene' */'@/pages/details-2.0/scene'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/shelfChecker/scene'), loading: LoadingComponent})],
            "title": "现场详情",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ResultPage__404' */'@/components/ResultPage/404'), loading: LoadingComponent}),
            "title": "404",
            "exact": true
          }
        ]
      },
      {
        "path": "/blindBox",
        "routes": [
          {
            "path": "/blindBox/details/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details-2.0__blindBox' */'@/pages/details-2.0/blindBox'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/shelfChecker/blindBox'), loading: LoadingComponent})],
            "title": "盲盒详情",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ResultPage__404' */'@/components/ResultPage/404'), loading: LoadingComponent}),
            "title": "404",
            "exact": true
          }
        ]
      },
      {
        "path": "/pay",
        "routes": [
          {
            "path": "/pay/success",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__payResult__success' */'@/pages/payResult/success'), loading: LoadingComponent}),
            "title": "支付结果",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ResultPage__404' */'@/components/ResultPage/404'), loading: LoadingComponent}),
            "title": "404",
            "exact": true
          }
        ]
      },
      {
        "path": "/user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user' */'@/pages/user'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/user/home/my",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home-2.0' */'@/pages/home-2.0'), loading: LoadingComponent}),
            "title": "用户主页",
            "exact": true
          },
          {
            "path": "/user/home/my/collection/example",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home-2.0' */'@/pages/home-2.0'), loading: LoadingComponent}),
            "title": "资产列表",
            "exact": true
          },
          {
            "path": "/user/home/my/blindBox/open/:bid",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home-2.0__components__BlindBoxOpenPage' */'@/pages/home-2.0/components/BlindBoxOpenPage'), loading: LoadingComponent}),
            "title": "盲盒列表",
            "exact": true
          },
          {
            "path": "/user/home/my/order-payload-detail/:orderId",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__order-payload-detail' */'@/pages/order-payload-detail'), loading: LoadingComponent}),
            "title": "订单详情",
            "exact": true
          },
          {
            "path": "/user/home/my/payment-record-detail/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__payment-record-detail' */'@/pages/payment-record-detail'), loading: LoadingComponent}),
            "title": "订单详情",
            "exact": true
          },
          {
            "path": "/user/invite",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite' */'@/pages/invite'), loading: LoadingComponent}),
            "title": "我的邀约",
            "exact": true
          },
          {
            "path": "/user/account",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account' */'@/pages/account'), loading: LoadingComponent}),
            "title": "账号管理",
            "exact": true
          },
          {
            "path": "/user/notice",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__notice' */'@/pages/notice'), loading: LoadingComponent}),
            "title": "用户须知",
            "exact": true
          },
          {
            "path": "/user/delivery-address",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__components__DeliveryAddress__list' */'@/pages/account/components/DeliveryAddress/list'), loading: LoadingComponent}),
            "title": "收货地址",
            "exact": true
          },
          {
            "path": "/user/delivery-address/change/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__components__DeliveryAddress__change' */'@/pages/account/components/DeliveryAddress/change'), loading: LoadingComponent}),
            "title": "新增收货地址",
            "exact": true
          },
          {
            "path": "/user/delivery-address/add",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__components__DeliveryAddress__change' */'@/pages/account/components/DeliveryAddress/change'), loading: LoadingComponent}),
            "title": "新增收货地址",
            "exact": true
          },
          {
            "path": "/user/messages",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__messages' */'@/pages/messages'), loading: LoadingComponent}),
            "title": "消息列表",
            "exact": true
          },
          {
            "path": "/user/mail/bind",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mail' */'@/pages/mail'), loading: LoadingComponent}),
            "title": "绑定邮箱",
            "exact": true
          },
          {
            "path": "/user/verify",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__verify' */'@/pages/verify'), loading: LoadingComponent}),
            "title": "实名认证",
            "exact": true
          },
          {
            "path": "/user/bank-card/add",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bankCard' */'@/pages/bankCard'), loading: LoadingComponent}),
            "title": "添加银行卡",
            "exact": true
          },
          {
            "path": "/user/bank-card/unbind",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bankCard' */'@/pages/bankCard'), loading: LoadingComponent}),
            "title": "解绑银行卡",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ResultPage__404' */'@/components/ResultPage/404'), loading: LoadingComponent}),
            "title": "404",
            "exact": true
          }
        ]
      },
      {
        "path": "/news",
        "routes": [
          {
            "path": "/news/index",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__news' */'@/pages/news'), loading: LoadingComponent}),
            "title": "公告",
            "exact": true
          },
          {
            "path": "/news/:name/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__news__detail' */'@/pages/news/detail'), loading: LoadingComponent}),
            "title": "公告详情",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ResultPage__404' */'@/components/ResultPage/404'), loading: LoadingComponent}),
            "title": "404",
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__ResultPage__404' */'@/components/ResultPage/404'), loading: LoadingComponent}),
        "title": "404",
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}

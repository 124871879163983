/**
* ! 文件由脚本生成，不要直接修改
*/
import defineAPIHOC from "../utils";

const defineAPI = defineAPIHOC('/rivtrust-core/api/v1');

const apiMap = {
  /** 活动查询-查询材料关联活动列表 */
  "GET /activity/byProductDefId/list": defineAPI<{ productDefId?: string }, Array<APICore.ActivitySimpleVo>>("/activity/byProductDefId/list", "GET"),
  /** 小程序-活动解锁-创建支付单 */
  "POST /activity/unlock/activity": defineAPI<APICore.UnlockClockInRequest, APICore.OrderUnlockActivityVo>("/activity/unlock/activity", "POST"),
  /** 小程序-活动解锁-退款 */
  "POST /activity/unlock/activity/refund": defineAPI<APICore.UnlockClockInRefundRequest, boolean>("/activity/unlock/activity/refund", "POST"),
  /** 小程序-活动-打卡 */
  "POST /activity/clockIn/reward": defineAPI<APICore.ClockInRewardRequest, APICore.OrderCreateVo>("/activity/clockIn/reward", "POST"),
  /** 小程序-活动-合成 */
  "POST /activity/compose": defineAPI<APICore.ComposeRequest, APICore.ComposeOrderCreateVo>("/activity/compose", "POST"),
  /** 小程序-活动-扫码获取 */
  "POST /activity/scan": defineAPI<APICore.ScanActivityRequest, APICore.OrderCreateVo>("/activity/scan", "POST"),
  /** 活动保险产品计划的保障信息查询 */
  "POST /activity/insurance-plan-security": defineAPI<APICore.ActivityInsurancePlanReq, Array<APICore.InsuranceProductPlanSecurityVo>>("/activity/insurance-plan-security", "POST"),
  /** 地图页 列表——不分页 城市查询 */
  "GET /activity/noAuth/clockIn/area/list": defineAPI<{ Name?: string, City?: string }, Array<APICore.ActivityClockIn4MapVo>>("/activity/noAuth/clockIn/area/list", "GET"),
  /** 活动 Tab 页列表分页查询 */
  "GET /activity/noAuth/clockIn/activityTab/page": defineAPI<{ Name?: string, City?: string, PageNum?: number, PageSize?: number }, APICore.ActivityClockInTabVoPagedData>("/activity/noAuth/clockIn/activityTab/page", "GET"),
  /** 按城市分组统计 */
  "GET /activity/noAuth/clockIn/cityGroup/list": defineAPI<null, Array<APICore.ActivityClockInGroupVo>>("/activity/noAuth/clockIn/cityGroup/list", "GET"),
  /** 活动 Tab 页列表搜索 */
  "GET /activity/noAuth/clockIn/activityTab/search": defineAPI<{ Name?: string, PageSize?: number, PageNum?: number }, Array<APICore.ActivityClockInTabVo>>("/activity/noAuth/clockIn/activityTab/search", "GET"),
  /** 空投活动分页查询 */
  "POST /activity/page": defineAPI<APICore.ActivityPageReq, APICore.ActivityPageVoPagedData>("/activity/page", "POST"),
  /** 活动详情 */
  "POST /activity/detail": defineAPI<APICore.AirdropActivityDetailReq, APICore.ActivityDetailVo>("/activity/detail", "POST"),
  /** 空投活动是否已预约 */
  "POST /activity/airdrop/had-apply": defineAPI<APICore.HadApplyReq, boolean>("/activity/airdrop/had-apply", "POST"),
  /** 空投-预约提醒 */
  "POST /activity/airdrop/apply/save-remind": defineAPI<APICore.ApplyRemindInfoSaveReq, boolean>("/activity/airdrop/apply/save-remind", "POST"),
  /** 我的所有活动报名记录-分页查询 */
  "POST /activity/apply/myRecord/page": defineAPI<APICore.ActivityApplyRecordPageReq, APICore.ActivityApplyRecordVoPagedData>("/activity/apply/myRecord/page", "POST"),
  /** 我的所有活动报名记录-分页查询 */
  "POST /activity/apply/batch/myRecord/page": defineAPI<APICore.ActivityApplyRecordPageReq, APICore.ActivityApplyRecordResultVoPagedData>("/activity/apply/batch/myRecord/page", "POST"),
  /** 通过签到码查询签到记录详情-noAuth */
  "POST /activity/apply/record/detail/{signCode}": defineAPI<{ signCode: string }, APICore.ActivityApplyRecordVo>("/activity/apply/record/detail/{signCode}", "POST", {divider: {"path":["signCode"]}}),
  /** 活动报名-参与 */
  "POST /activity/apply/join": defineAPI<APICore.ActivityApplyJoinReq, string>("/activity/apply/join", "POST"),
  /** 活动报名信息-提交 */
  "POST /activity/apply/info/submit": defineAPI<APICore.ActivityApplySubmitCommand, boolean>("/activity/apply/info/submit", "POST"),
  /** 活动报名信息(批量)-提交 */
  "POST /activity/apply/batch/info/submit": defineAPI<APICore.BatchApplyInfoSubmitReq, boolean>("/activity/apply/batch/info/submit", "POST"),
  /** 活动报名-创建付款单 */
  "POST /activity/apply/order/create": defineAPI<APICore.ActivityApplyOrderCreateReq, APICore.OrderCreateVo>("/activity/apply/order/create", "POST"),
  /** 活动报名(批量报名记录)-创建付款单 */
  "POST /activity/apply/batch/order/create": defineAPI<APICore.BatchActivityApplyOrderCreateReq, APICore.OrderCreateVo>("/activity/apply/batch/order/create", "POST"),
  /** 对应活动我的报名信息记录-查看 */
  "GET /activity/apply/my/applyRecord": defineAPI<{ activityId?: string }, APICore.ActivityApplyRecordVo>("/activity/apply/my/applyRecord", "GET"),
  /** 对应活动我的历史报名信息 */
  "GET /activity/apply/my/history/applyInfo": defineAPI<{ activityId?: string }, APICore.MyHistoryApplyInfoVo>("/activity/apply/my/history/applyInfo", "GET"),
  /** 对应活动我的报名信息记录-查看 */
  "GET /activity/apply/batch/my/applyRecord": defineAPI<{ activityId?: string }, APICore.MyActivityApplyResultVo>("/activity/apply/batch/my/applyRecord", "GET"),
  /** M商家端-核验 */
  "POST /activity/apply/applySign/{signCode}": defineAPI<{ signCode: string }, boolean>("/activity/apply/applySign/{signCode}", "POST", {divider: {"path":["signCode"]}}),
  /** M商家端(未指定核销人/无需鉴权)-核验 */
  "POST /activity/apply/noAuth/applySign/{signCode}": defineAPI<{ signCode: string }, boolean>("/activity/apply/noAuth/applySign/{signCode}", "POST", {divider: {"path":["signCode"]}}),
  /** M商家端-签到数据统计 */
  "POST /activity/apply/sign/statics": defineAPI<null, APICore.ActivitySignStaticsVo>("/activity/apply/sign/statics", "POST"),
  /** M商家端-我签到活动列表查询 */
  "POST /activity/apply/sign/activity/page": defineAPI<APICore.SignActivityPageReq, APICore.SignActivityVoPagedData>("/activity/apply/sign/activity/page", "POST"),
  /** M商家端-我签到的记录分页查询 */
  "POST /activity/apply/signRecord/page": defineAPI<APICore.SignActivityUserPageReq, APICore.SignRecordVoPagedData>("/activity/apply/signRecord/page", "POST"),
  /** 通过签到记录查询签到信息 */
  "POST /activity/apply/record/detailById/{applyRecordId}": defineAPI<{ applyRecordId: string }, APICore.ActivityApplyRecordVo>("/activity/apply/record/detailById/{applyRecordId}", "POST", {divider: {"path":["applyRecordId"]}}),
  /** 活动-报名信息创建 */
  "POST /service/activity/apply/create": defineAPI<APICore.ActivityApplyCreateRo, boolean>("/service/activity/apply/create", "POST"),
  /** GetApplyFillInfoPropertyDtoListById */
  "POST /service/activity/apply/applyFillInfoPropertyList": defineAPI<{ activityId?: number }, Array<APICore.ApplyFillInfoPropertyDto>>("/service/activity/apply/applyFillInfoPropertyList", "POST", {divider: {"query":["activityId"]}}),
  /** 小程序-活动打卡点-创建支付单 */
  "POST /activityLocation/unlock/activityLocation": defineAPI<APICore.UnlockClockInLocationRequest, APICore.OrderUnlockActivityVo>("/activityLocation/unlock/activityLocation", "POST"),
  /** 小程序-活动解锁-退款 */
  "POST /activityLocation/unlock/activityLocation/refund": defineAPI<APICore.UnlockClockInLocationRefundRequest, boolean>("/activityLocation/unlock/activityLocation/refund", "POST"),
  /** 小程序-付费打卡-创建支付单 */
  "POST /activityLocation/pay-clock-in/activityLocation": defineAPI<APICore.PayClockInLocationRequest, APICore.OrderUnlockActivityVo>("/activityLocation/pay-clock-in/activityLocation", "POST"),
  /** 小程序-活动解锁-退款 */
  "POST /activityLocation/pay-clock-in/activityLocation/refund": defineAPI<APICore.UnlockClockInLocationRefundRequest, boolean>("/activityLocation/pay-clock-in/activityLocation/refund", "POST"),
  /** 活动模板列表 */
  "GET /activity/template/list": defineAPI<null, Array<APICore.ActivityTemplateListVo>>("/activity/template/list", "GET"),
  /** 活动模板详情 */
  "GET /activity/template/detail": defineAPI<{ ActivityTemplateId?: number }, Array<APICore.ActivityTemplateDetailVo>>("/activity/template/detail", "GET"),
  /** 创建官网用户订单 */
  "POST /official/admin-order/create": defineAPI<APICore.CreateAdminOrderRequest, APICore.AdminOrderCreateVo>("/official/admin-order/create", "POST"),
  /** 官网用户订单支付回调接口 */
  "POST /official/admin-order/notify": defineAPI<null, null>("/official/admin-order/notify", "POST"),
  /** 取消订单 */
  "POST /official/admin-order/cancel": defineAPI<APICore.CancelAdminOrderReq, boolean>("/official/admin-order/cancel", "POST"),
  /** 我的订单分页查询 */
  "POST /official/admin-order/page": defineAPI<APICore.AdminOrderPageReq, APICore.AdminOrderVoPagedData>("/official/admin-order/page", "POST"),
  /** 订单详情查询 */
  "GET /official/admin-order/detail": defineAPI<{ orderCode?: string }, APICore.AdminOrderVo>("/official/admin-order/detail", "GET"),
  /** 广告列表 */
  "POST /advertisement/list": defineAPI<APICore.AdvertisementQueryReq, Array<APICore.AdvertisementVo>>("/advertisement/list", "POST"),
  /** 广告查看回执 */
  "POST /advertisement/read": defineAPI<APICore.CreateAdvertisementViewRecordReq, boolean>("/advertisement/read", "POST"),
  /** 用户点击广告 */
  "POST /advertisement/click-on": defineAPI<APICore.ClickOnAdvertisementReq, string>("/advertisement/click-on", "POST"),
  /** 用户预约提醒查询 */
  "POST /remind/apply/find-user-remind-info": defineAPI<APICore.FindUserRemindInfoReq, boolean>("/remind/apply/find-user-remind-info", "POST"),
  /** 保存申领报名信息 */
  "POST /remind/apply/create": defineAPI<APICore.ApplyInfoCreateReq, string>("/remind/apply/create", "POST"),
  /** 直接申领 */
  "POST /remind/apply/direct-get": defineAPI<APICore.ApplyInfoCreateReq, APICore.AirdropDirectGetOrderCreatedVo>("/remind/apply/direct-get", "POST"),
  /** 查询用户成功申领次数（注：新需求里，创建时配置的限额与ApplyInfo表存的记录都代表参与空投领取的次数） */
  "GET /remind/apply/get-user-success-apply": defineAPI<{ airdropId?: string }, number>("/remind/apply/get-user-success-apply", "GET"),
  /** 空投的白名单校验 */
  "POST /app-opt/airdrop-verify-no-pre": defineAPI<APICore.AppOptCheckReq, boolean>("/app-opt/airdrop-verify-no-pre", "POST"),
  /** 根据源应用操作单ID查询 */
  "POST /appOptProduct/GetListBySrcId": defineAPI<APICore.OptProductSrcIdReq, Array<APICore.AppOptProductVo>>("/appOptProduct/GetListBySrcId", "POST"),
  /** 根据模板Id查询可转入交易所时间 */
  "GET /asset-to-exchange/transfer-time": defineAPI<{ productDefId?: string }, APICore.TransferTimeVo>("/asset-to-exchange/transfer-time", "GET"),
  /** 单笔资产转入交易所 */
  "POST /asset-to-exchange/transfer": defineAPI<APICore.TransferToExchangeReq, string>("/asset-to-exchange/transfer", "POST"),
  /** 批量转入交易所 */
  "POST /asset-to-exchange/batch-transfer": defineAPI<APICore.BatchTransferToExchangeReq, string>("/asset-to-exchange/batch-transfer", "POST"),
  /** 校验TokenID是否已正式备案登记 */
  "POST /asset-to-exchange/check": defineAPI<APICore.CheckTransferToExchangeReq, Array<APICore.CheckTransferToExchangeResp>>("/asset-to-exchange/check", "POST"),
  /** 盲盒查询已购买数和限购数 */
  "POST /blindBox/blind/bought": defineAPI<APICore.BlindBoughtLimitReq, APICore.BlindBoxBoughtVo>("/blindBox/blind/bought", "POST"),
  /** 盲盒购买-创建订单 */
  "POST /blindBox/purchase": defineAPI<APICore.BlindBoxSaleRequest, APICore.OrderCreateVo>("/blindBox/purchase", "POST"),
  /** 判断盲盒是否全交易完成 */
  "POST /blindBox/if-all-blind-box-success": defineAPI<APICore.BlindBoxIfAllSuccessRequest, boolean>("/blindBox/if-all-blind-box-success", "POST"),
  /** 普通购买（不带盲盒）-创建订单 */
  "POST /blindBox/open-blind-box": defineAPI<APICore.BlindBoxOpenRequest, boolean>("/blindBox/open-blind-box", "POST"),
  /** 盲盒商品订单记录详情明细 */
  "POST /blindBox/blind-box-product-detail": defineAPI<APICore.BlindBoxProductDetailReq, Array<APICore.BlindBoxProductsPCVo>>("/blindBox/blind-box-product-detail", "POST"),
  /**  */
  "POST /box-publish-mgt/detail-by-id": defineAPI<APICore.QueryDetailBoxPublishMgtReq, APICore.BoxPublishMgtDetailVo>("/box-publish-mgt/detail-by-id", "POST"),
  /** 预约提醒 */
  "POST /box-publish-mgt/apply/save-remind": defineAPI<APICore.ApplyRemindInfoSaveReq, boolean>("/box-publish-mgt/apply/save-remind", "POST"),
  /** 上架单-优先购买-校验 */
  "POST /box-publish-mgt/pre-purchase/verify": defineAPI<APICore.PrePurchaseVerifyReq, boolean>("/box-publish-mgt/pre-purchase/verify", "POST"),
  /** 刷卡获取奖励 */
  "POST /device/swipe": defineAPI<APICore.SwipeToGetAwardReq, Array<APICore.AwardVo>>("/device/swipe", "POST"),
  /** 获取刷卡点类型 */
  "POST /device/check": defineAPI<APICore.CheckReq, boolean>("/device/check", "POST"),
  /** 获取刷卡点信息接口 */
  "POST /device/getCardTerminalInfo": defineAPI<APICore.GetCardTerminalInfoReq, APICore.DeviceCardTerminalInfoVo>("/device/getCardTerminalInfo", "POST"),
  /** 绑定实体卡 */
  "POST /card-package/bind": defineAPI<APICore.BindEntityCardReq, APICore.BindEntityCardResponse>("/card-package/bind", "POST"),
  /** 卡包列表（仅展示） */
  "POST /card-package/cards": defineAPI<null, Array<APICore.PackageCardVo>>("/card-package/cards", "POST"),
  /** 卡片奖励详情 （仅展示） */
  "POST /card-package/card-detail": defineAPI<APICore.GetCardRewardDetailReq, APICore.CardDetailVo>("/card-package/card-detail", "POST"),
  /** 卡互活动详情 */
  "POST /card-terminal-activity/detail": defineAPI<APICore.CardTerminalActivityDetailReq, APICore.CardTerminalActivityDetailVo>("/card-terminal-activity/detail", "POST"),
  /** 启动装置 */
  "POST /deviceOperation/start": defineAPI<null, boolean>("/deviceOperation/start", "POST"),
  /** 是否可启动装置 */
  "POST /deviceOperation/available": defineAPI<null, APICore.AvailableVo>("/deviceOperation/available", "POST"),
  /** Kitty 创建新的小程序二维码 */
  "GET /rivtrust-core/devops/kitty/new-code": defineAPI<null, APICore.NewCodeVo>("/rivtrust-core/devops/kitty/new-code", "GET"),
  /** 发送设备启动信号 */
  "GET /rivtrust-core/devops/kitty/send-start-command": defineAPI<null, boolean>("/rivtrust-core/devops/kitty/send-start-command", "GET"),
  /** 根据研学码查询研学活动 */
  "POST /educationalActivity/by-code": defineAPI<APICore.EducationalActivityByCodeReq, APICore.EducationalActivityBaseVo>("/educationalActivity/by-code", "POST"),
  /** 绑定研学码 */
  "POST /educationalActivity/binding-educational-code": defineAPI<APICore.BindingEducationalCodeReq, boolean>("/educationalActivity/binding-educational-code", "POST"),
  /** 解锁第一个打卡点-仅针对不需要绑定研学码的活动 */
  "POST /educationalActivity/unlock-first-location": defineAPI<APICore.EducationalActivityIdRequest, boolean>("/educationalActivity/unlock-first-location", "POST"),
  /** 打卡研学活动 */
  "POST /educationalActivity/clockIn": defineAPI<APICore.ClockInEducationalActivityReq, APICore.OrderCreateVo>("/educationalActivity/clockIn", "POST"),
  /** 活动查询-用户是否已解锁该活动 */
  "POST /educationalActivity/is-activity-unlocked": defineAPI<APICore.EducationalActivityIdRequest, boolean>("/educationalActivity/is-activity-unlocked", "POST"),
  /** 活动查询-研学活动详情 */
  "POST /educationalActivity/detail": defineAPI<APICore.EducationalActivityIdRequest, APICore.EducationalActivityVo>("/educationalActivity/detail", "POST"),
  /** 研学活动完成当前打卡点，解锁下一个打卡点（线索解谜成功后调用，解锁下一个打卡点，支持当前点没有线索题，支持没有下一个打卡点） */
  "POST /educationalActivity/current-location-finished": defineAPI<APICore.UnlockEducationalActivityLocationReq, boolean>("/educationalActivity/current-location-finished", "POST"),
  /** 研学活动-查询用户打卡/解谜/解锁状态 */
  "POST /educationalActivity/clockInStatus": defineAPI<APICore.EducationalActivityIdRequest, Array<APICore.EducationalActivityClockInStatusResponse>>("/educationalActivity/clockInStatus", "POST"),
  /** 研学打卡点答题上报 */
  "POST /educationalActivity/answer": defineAPI<APICore.EducationalActivityLocationAnswerReq, boolean>("/educationalActivity/answer", "POST"),
  /** 查询打卡点配置题目作答状态 */
  "POST /educationalActivity/question-answer-status": defineAPI<APICore.QuestionAnswerStatusReq, Array<APICore.QuestionAnswerStatusVo>>("/educationalActivity/question-answer-status", "POST"),
  /** 根据报名记录查看保单 */
  "POST /insurance/policy": defineAPI<APICore.InsurancePolicyReq, string>("/insurance/policy", "POST"),
  /** 查询权益商家 */
  "GET /client/merchant/getMerchant": defineAPI<{ id?: string }, APICore.MerchantNewVo>("/client/merchant/getMerchant", "GET"),
  /** 获取当前登录用户所属商家信息 */
  "POST /merchant/merchant/info": defineAPI<null, APICore.MerchantVo>("/merchant/merchant/info", "POST"),
  /** 编辑商家名称 */
  "POST /merchant/merchant/edit-name": defineAPI<APICore.EditMerchantNameReq, boolean>("/merchant/merchant/edit-name", "POST"),
  /** 编辑权益商家联系方式 */
  "POST /merchant/merchant/edit-contact-way": defineAPI<APICore.EditMerchantContactWayReq, boolean>("/merchant/merchant/edit-contact-way", "POST"),
  /** 编辑权益商家地址 */
  "POST /merchant/merchant/edit-address": defineAPI<APICore.EditMerchantAddressReq, boolean>("/merchant/merchant/edit-address", "POST"),
  /** 编辑权益商家头像 */
  "POST /merchant/merchant/edit-avatar": defineAPI<APICore.EditMerchantAvatarReq, boolean>("/merchant/merchant/edit-avatar", "POST"),
  /** 获取当前商家员工列表 */
  "POST /merchant/merchant/employees": defineAPI<null, Array<APICore.EmployeeVo>>("/merchant/merchant/employees", "POST"),
  /** 编辑商家员工 */
  "POST /merchant/merchant/edit-employees-status": defineAPI<APICore.EditMerchantEmployeeStatusReq, boolean>("/merchant/merchant/edit-employees-status", "POST"),
  /** 获取当前登录用户信息 */
  "POST /merchant/employee/info": defineAPI<null, APICore.EmployeeVo>("/merchant/employee/info", "POST"),
  /** 创建商家员工 */
  "POST /merchant/employee/create": defineAPI<APICore.CreateMerchantEmployeeReq, string>("/merchant/employee/create", "POST"),
  /** 编辑商家员工基本信息 */
  "POST /merchant/employee/edit": defineAPI<APICore.EditEmployeeBaseInfoReq, boolean>("/merchant/employee/edit", "POST"),
  /** 我的聚合权益列表查询条件商家-小程序 */
  "GET /myCoupon/searchMyCouponMchList": defineAPI<null, Array<APICore.MyCouponMchVo>>("/myCoupon/searchMyCouponMchList", "GET"),
  /** 我的聚合权益列表查询-小程序 */
  "POST /myCoupon/searchMyCouponAssetPage": defineAPI<APICore.SearchMyCouponAssetPageReq, APICore.MyCouponAssetPageVoPagedData>("/myCoupon/searchMyCouponAssetPage", "POST"),
  /** 我的当年聚合权益列表查询条件商家-仅供世博用 */
  "GET /myCoupon/searchMyCouponThisYearMchList": defineAPI<null, Array<APICore.MyCouponMchVo>>("/myCoupon/searchMyCouponThisYearMchList", "GET"),
  /** 删除过期权益 */
  "POST /myCoupon/deleteExpireCoupons": defineAPI<APICore.DeleteExpireCouponsReq, boolean>("/myCoupon/deleteExpireCoupons", "POST"),
  /** 删除我的权益聚合 */
  "POST /myCoupon/deleteMyCouponAsset": defineAPI<APICore.DeleteMyCouponAssetReq, APICore.DeleteMyCouponAssetResultVo>("/myCoupon/deleteMyCouponAsset", "POST"),
  /** 我的当年聚合权益列表查询-仅供世博用 */
  "POST /myCoupon/searchMyCouponAssetThisYearPage": defineAPI<APICore.SearchMyCouponAssetThisYearPageReq, APICore.MyCouponAssetPageVoPagedData>("/myCoupon/searchMyCouponAssetThisYearPage", "POST"),
  /** 我的权益详情查询-带明细-小程序 */
  "POST /myCoupon/itemDetail": defineAPI<APICore.MyCouponItemDetailReq, APICore.MyCouponAssetVo>("/myCoupon/itemDetail", "POST"),
  /** 分享锁定 */
  "POST /myCoupon/sharing/lock": defineAPI<APICore.MyCouponSharingLockRequest, Array<APICore.MyCouponItemVo>>("/myCoupon/sharing/lock", "POST"),
  /** 分享释放 */
  "POST /myCoupon/sharing/release": defineAPI<APICore.MyCouponSharingClearRequest, boolean>("/myCoupon/sharing/release", "POST"),
  /** 查询用户总积分、队伍数、活动报名数 */
  "GET /my-info": defineAPI<null, APICore.MyInfoVo>("/my-info", "GET"),
  /** 我的聚合藏品列表查询条件发行方-小程序 */
  "GET /myProduct/searchMyProductIssuerList": defineAPI<null, Array<APICore.MyProductIssuerVo>>("/myProduct/searchMyProductIssuerList", "GET"),
  /** 我的聚合藏品列表查询-小程序 */
  "POST /myProduct/searchMyProductAssetPage": defineAPI<APICore.SearchMyProductAssetPageReq, APICore.MyProductAssetPageVoProductTotalPagedData>("/myProduct/searchMyProductAssetPage", "POST"),
  /** 我的藏品明细列表翻页查询-小程序 */
  "POST /myProduct/searchMyProductItemPage": defineAPI<APICore.SearchMyProductItemPageReq, APICore.MyProductItemPageVoPagedData>("/myProduct/searchMyProductItemPage", "POST"),
  /** 我的藏品明细详情查询-小程序 */
  "POST /myProduct/itemDetail": defineAPI<APICore.MyProductItemDetailReq, APICore.MyProductItemVo>("/myProduct/itemDetail", "POST"),
  /** 我的聚合藏品头像查询-小程序 */
  "POST /myProduct/searchMyProductAvatar": defineAPI<APICore.MyProductAvatarReq, APICore.StringPagedData>("/myProduct/searchMyProductAvatar", "POST"),
  /** 获取我参与并得到礼品的活动列表 */
  "POST /myProduct/listMyActivity": defineAPI<null, Array<APICore.MyActivityVo>>("/myProduct/listMyActivity", "POST"),
  /** 获取活动下的礼品列表信息 */
  "POST /myProduct/listMyActivityItem": defineAPI<APICore.MyActivityItemReq, Array<APICore.ItemVO>>("/myProduct/listMyActivityItem", "POST"),
  /** 删除过期权益 */
  "POST /myProduct/deleteExpireCoupons": defineAPI<APICore.DeleteExpireCouponsReq, boolean>("/myProduct/deleteExpireCoupons", "POST"),
  /** 批量删除我的藏品 */
  "POST /myProduct/deleteMyProductItem": defineAPI<APICore.DeleteMyProductItemReq, boolean>("/myProduct/deleteMyProductItem", "POST"),
  /** 删除我的藏品聚合 */
  "POST /myProduct/deleteMyProductAsset": defineAPI<APICore.DeleteMyProductAssetReq, APICore.DeleteMyProductAssetResultVo>("/myProduct/deleteMyProductAsset", "POST"),
  /** SharingLock */
  "POST /myProduct/sharing/lock": defineAPI<APICore.MyProductSharingLockRequest, Array<APICore.MyProductItemVo>>("/myProduct/sharing/lock", "POST"),
  /** SharingRelease */
  "POST /myProduct/sharing/release": defineAPI<APICore.MyProductSharingClearRequest, boolean>("/myProduct/sharing/release", "POST"),
  /** 查询拥有者列表 */
  "POST /myProduct/getOwnerByRefProductDefId": defineAPI<APICore.GetOwnerByRefProductDefIdRequest, Array<APICore.UserBaseVo>>("/myProduct/getOwnerByRefProductDefId", "POST"),
  /** 我的藏品查询-按年分组-仅供世博用！！！ */
  "POST /myProduct/searchMyProductAssetByYear": defineAPI<APICore.SearchMyProductAssetByYearReq, APICore.MyProductAssetForWorldExpoVo>("/myProduct/searchMyProductAssetByYear", "POST"),
  /** 支付单成功 */
  "POST /service/pay/payOrderSuccess": defineAPI<APICore.OrderPaySuccessRO, string>("/service/pay/payOrderSuccess", "POST"),
  /** 支付单退款 */
  "POST /service/pay/payOrderRefund": defineAPI<APICore.OrderRefundSuccessRO, string>("/service/pay/payOrderRefund", "POST"),
  /** 设置支付单不可退款 */
  "POST /service/pay/disableRefundPayOrder": defineAPI<APICore.DisableRefundPayOrderRO, boolean>("/service/pay/disableRefundPayOrder", "POST"),
  /** 设置支付单可退款 */
  "POST /service/pay/CanRefundPayOrder": defineAPI<APICore.CanRefundPayOrderRO, boolean>("/service/pay/CanRefundPayOrder", "POST"),
  /** 支付订单列表查询 */
  "POST /payOrder/searchPayOrderPage": defineAPI<APICore.SearchPayOrderPageReq, APICore.PayOrderPageVoPagedData>("/payOrder/searchPayOrderPage", "POST"),
  /** 支付订单详情 */
  "POST /payOrder/detail": defineAPI<APICore.PayOrderDetailRequest, APICore.PayOrderVo>("/payOrder/detail", "POST"),
  /** 支付订单列表查询-PC端 */
  "POST /payOrder/searchPageForPC": defineAPI<APICore.SearchPayOrderPageForPcReq, APICore.PayOrderPageForPcVoPagedData>("/payOrder/searchPageForPC", "POST"),
  /** 支付订单详情-PC端 */
  "POST /payOrder/detailForPC": defineAPI<APICore.PayOrderDetailRequest, APICore.PayOrderForPcVo>("/payOrder/detailForPC", "POST"),
  /** 支付订单-微信支付 */
  "POST /payOrder/wechat/pay": defineAPI<APICore.PayPayOrderRequest, string>("/payOrder/wechat/pay", "POST"),
  /** 支付订单-支付宝支付 */
  "POST /payOrder/alipay/pay": defineAPI<APICore.PayPayOrderRequest, string>("/payOrder/alipay/pay", "POST"),
  /** 支付订单-支付ForDap */
  "POST /payOrder/dap/pay": defineAPI<APICore.PayPayOrderRequest, string>("/payOrder/dap/pay", "POST"),
  /** 取消支付 */
  "POST /payOrder/cancel": defineAPI<APICore.PayOrderCancelRequest, boolean>("/payOrder/cancel", "POST"),
  /** 退款 */
  "POST /payOrder/refund": defineAPI<APICore.PayOrderRefundRequest, boolean>("/payOrder/refund", "POST"),
  /** 支付订单-付款-银联支付-PC */
  "POST /payOrder/pc/unionPay": defineAPI<APICore.PayPayOrderRequest, APICore.UmQrPayQrCodeResponse>("/payOrder/pc/unionPay", "POST"),
  /** 官网用户订单分页查询 */
  "POST /platform/admin-order/page": defineAPI<APICore.PlatformAdminOrderPageReq, APICore.PlatformAdminOrderVoPagedData>("/platform/admin-order/page", "POST"),
  /** 官网用户订单详情 */
  "POST /platform/admin-order/detail": defineAPI<APICore.PlatformAdminOrderDetailReq, APICore.PlatformAdminOrderDetailVo>("/platform/admin-order/detail", "POST"),
  /** 创建服务套餐 */
  "POST /platform/service-package/create": defineAPI<APICore.CreateServicePackageReq, string>("/platform/service-package/create", "POST"),
  /** 编辑服务套餐 */
  "POST /platform/service-package/modify": defineAPI<APICore.ModifyServicePackageReq, boolean>("/platform/service-package/modify", "POST"),
  /** 删除服务套餐 */
  "POST /platform/service-package/delete": defineAPI<APICore.DeleteServicePackageReq, boolean>("/platform/service-package/delete", "POST"),
  /** 服务套餐列表翻页查询 */
  "POST /platform/service-package/page": defineAPI<APICore.ServicePackagePageReq, APICore.ServicePackageBaseVoPagedData>("/platform/service-package/page", "POST"),
  /** 服务套餐详情 */
  "POST /platform/service-package/detail": defineAPI<APICore.ServicePackageDetailReq, APICore.ServicePackageDetailVo>("/platform/service-package/detail", "POST"),
  /** 查询用户积分总数 */
  "GET /point/total": defineAPI<null, number>("/point/total", "GET"),
  /** 查询用户积分获得明细-分页 */
  "POST /point/item": defineAPI<APICore.SearchPointItemPageReq, APICore.PointItemVoPagedData>("/point/item", "POST"),
  /** 查询用户积分使用明细 */
  "POST /point/consume-item": defineAPI<APICore.SearchPointItemPageReq, APICore.PointItemVoPagedData>("/point/consume-item", "POST"),
  /** 查寻是否有库存 */
  "GET /productDef/isProductDefReward": defineAPI<{ srcId?: number, srcType?: number }, boolean>("/productDef/isProductDefReward", "GET"),
  /**  */
  "POST /productDef/detail-by-id": defineAPI<APICore.ProductDefDetailReq, APICore.ProductDefVo>("/productDef/detail-by-id", "POST"),
  /** 确认兑换 */
  "POST /productRedeem/redeem": defineAPI<APICore.ProductRedeemReq, boolean>("/productRedeem/redeem", "POST"),
  /** 兑换数量统计-M端 */
  "POST /productRedeem/mch/redeemStatistics": defineAPI<null, APICore.ProductRedeemStaticsVo>("/productRedeem/mch/redeemStatistics", "POST"),
  /** 礼物记录订单-分页查询 */
  "POST /productRedeem/mch/tradeOrder/page": defineAPI<APICore.TradOrderRedeemPageQueryReq, APICore.TradeOrderVoPagedData>("/productRedeem/mch/tradeOrder/page", "POST"),
  /** 商品订单记录详情-根据订单id查询 */
  "POST /productRedeem/mch/tradeOrder/detailById": defineAPI<{ tradeOrderId?: string }, APICore.TradeOrderDetailVo>("/productRedeem/mch/tradeOrder/detailById", "POST", {divider: {"query":["tradeOrderId"]}}),
  /** 查询上架单详情 */
  "POST /publish/detail-by-id": defineAPI<APICore.PublishMgtDetailReq, APICore.PublishMgtDetailVo>("/publish/detail-by-id", "POST"),
  /** 根据藏品定义Id查询上架单详情 */
  "POST /publish/detail-by-product-def-id": defineAPI<APICore.PublishMgtDetailByProductDefIdReq, APICore.PublishMgtDetailVo>("/publish/detail-by-product-def-id", "POST"),
  /** 普通购买（不带盲盒）-创建订单 */
  "POST /publish/purchase": defineAPI<APICore.PublishSaleRequest, APICore.OrderCreateVo>("/publish/purchase", "POST"),
  /** 预约提醒 */
  "POST /publish/apply/save-remind": defineAPI<APICore.ApplyRemindInfoSaveReq, boolean>("/publish/apply/save-remind", "POST"),
  /** 上架单-优先购买-校验 */
  "POST /publish/pre-purchase/verify": defineAPI<APICore.PrePurchaseVerifyReq, boolean>("/publish/pre-purchase/verify", "POST"),
  /** 商品详情用户作品可购买数量 */
  "POST /publish/purchase/limit": defineAPI<APICore.ProductDefPurchaseLimitReq, APICore.ProductDefPurchaseLimitVo>("/publish/purchase/limit", "POST"),
  /** 兑换码详情查询 */
  "POST /redeem-code/detail": defineAPI<APICore.RedeemCodeDetailReq, APICore.RedeemCodeDetailVo>("/redeem-code/detail", "POST"),
  /** 打卡故事活动 */
  "POST /rpgActivity/clockInRpgActivity": defineAPI<APICore.ClockInRpgActivityCommand, string>("/rpgActivity/clockInRpgActivity", "POST"),
  /** 活动查询-故事活动详情 */
  "POST /rpgActivity/detail": defineAPI<APICore.RpgActivityDetailRequest, APICore.RpgActivityForCVo>("/rpgActivity/detail", "POST"),
  /** 活动查询-查询活动下已打卡打卡点 */
  "POST /rpgActivity/clockInStatus": defineAPI<APICore.RpgActivityClockInStatusRequest, Array<APICore.RpgActivityClockInStatusResponse>>("/rpgActivity/clockInStatus", "POST"),
  /** 小程序-活动-打卡 */
  "POST /service/activity/clockIn/reward": defineAPI<APICore.ServiceClockInRewardRequest, APICore.OrderCreateVo>("/service/activity/clockIn/reward", "POST"),
  /** 创建空投订单 */
  "POST /service/activity/create-trade-order/airdrop-direct-get": defineAPI<APICore.AirDropDirectGetCreateTradeOrderRequest, APICore.AirdropDirectGetOrderCreateVo>("/service/activity/create-trade-order/airdrop-direct-get", "POST"),
  /** 发起创建空投订单Job */
  "POST /service/activity/create-trade-order/airdrop": defineAPI<APICore.AirDropCreateTradeOrderRequest, boolean>("/service/activity/create-trade-order/airdrop", "POST"),
  /** 根据活动id获取对应的库存数量 */
  "GET /service/activity/award-qty": defineAPI<{ activityIds?: Array<number> }, {}>("/service/activity/award-qty", "GET"),
  /** 根据活动id查询配置的奖励数 */
  "GET /service/activity/def-award-qty": defineAPI<{ activityId?: number }, {}>("/service/activity/def-award-qty", "GET"),
  /** 自动发放奖励 */
  "POST /service/autoSend/reward": defineAPI<APICore.AutoSendCreateOrderData, boolean>("/service/autoSend/reward", "POST"),
  /** 获取发行方详情-RPC */
  "POST /service/issue/detail": defineAPI<APICore.IssueDetailServiceReq, APICore.IssueDetailVo>("/service/issue/detail", "POST"),
  /** 根据productDefId列表跟ownerId查询用户拥有的tokenId列表 */
  "POST /service/my-product/userTokenIdsByDefId": defineAPI<APICore.UserProductByDefIdReq, Array<string>>("/service/my-product/userTokenIdsByDefId", "POST"),
  /** 根据productDefId列表跟ownerId查询用户拥有的tokenId列表 */
  "POST /service/my-product/userMyProductItemVoByDefId": defineAPI<APICore.UserProductByDefIdReq, Array<APICore.MyProductItemVo>>("/service/my-product/userMyProductItemVoByDefId", "POST"),
  /** 根据productDefId列表跟ownerId查询用户拥有的tokenId列表 */
  "POST /service/my-product/userMyProductItemCountAfterActivityStartByDefId": defineAPI<APICore.UserProductAfterActivityStartByDefIdReq, number>("/service/my-product/userMyProductItemCountAfterActivityStartByDefId", "POST"),
  /** 翻页查询用户拥有的藏品列表（给product调用） */
  "POST /service/my-product/pageProdResp": defineAPI<APICore.PageProdRespReq, APICore.ProductRespPagedData>("/service/my-product/pageProdResp", "POST"),
  /** 查询拥有者列表 */
  "POST /service/my-product/ownerByProductDefIds": defineAPI<APICore.GetOwnerByRefProductDefIdsRequest, Array<APICore.ProductOwnerBaseVo>>("/service/my-product/ownerByProductDefIds", "POST"),
  /** 查询用户已合成个数（给product调用） */
  "POST /service/my-product/countOwnerProductDef": defineAPI<APICore.CountOwnerProductDefReq, number>("/service/my-product/countOwnerProductDef", "POST"),
  /** 根据tokenId查询对应的product信息（给product调用） */
  "POST /service/my-product/productRespByTokenId": defineAPI<{ tokenId?: string, assetType?: number }, APICore.ProductResp>("/service/my-product/productRespByTokenId", "POST", {divider: {"query":["tokenId","assetType"]}}),
  /** 服务套餐列表查询 */
  "GET /official/service-package/list": defineAPI<null, Array<APICore.ServicePackageForWebVo>>("/official/service-package/list", "GET"),
  /** 获取发行方详情-RPC */
  "POST /service/pay-order/limit/detail": defineAPI<APICore.PayOrderLimitDetailRequest, APICore.PayOrderLimitVo>("/service/pay-order/limit/detail", "POST"),
  /** 锁定库存 */
  "POST /service/productDef/inventory/lock": defineAPI<APICore.IssueInventoryLockCommand, boolean>("/service/productDef/inventory/lock", "POST"),
  /** 清空锁定库存 */
  "POST /service/productDef/inventory/clear": defineAPI<APICore.InventoryClearCommand, boolean>("/service/productDef/inventory/clear", "POST"),
  /** 更改库存奖励发放方式 */
  "POST /service/productDef/inventory/ModifySendAwardWay": defineAPI<APICore.ProductDefSendAwardWayReq, boolean>("/service/productDef/inventory/ModifySendAwardWay", "POST"),
  /** 批量新增多个打卡点下的兑换码 */
  "POST /service/redeem-code/location/batch-add": defineAPI<APICore.ServiceRedeemCodeBatchAddReq, boolean>("/service/redeem-code/location/batch-add", "POST"),
  /** 修改打卡点下-兑换码 */
  "POST /service/redeem-code/location/modify": defineAPI<APICore.ServiceLocationRedeemCodeBatchModifyReq, boolean>("/service/redeem-code/location/modify", "POST"),
  /** 记录邀请加入队伍记录 */
  "POST /service/team/record-build-team": defineAPI<APICore.RecordBuildTeamReq, boolean>("/service/team/record-build-team", "POST"),
  /** 记录邀请加入队伍记录 */
  "POST /service/team/record-invite-join-team": defineAPI<APICore.RecordInviteJoinTeamReq, boolean>("/service/team/record-invite-join-team", "POST"),
  /** 获取活动的引用数据 */
  "POST /service/theme-activity/activity-reference-data": defineAPI<APICore.ActivityReferenceDataReq, {}>("/service/theme-activity/activity-reference-data", "POST"),
  /** 获取发行方详情-RPC */
  "POST /service/trade-order/orderItemProductList": defineAPI<APICore.TradeOrderItemProductRequest, Array<APICore.OrderItemProductVo>>("/service/trade-order/orderItemProductList", "POST"),
  /** 获取自动发放活动的发放人数 */
  "POST /service/trade-order/autoSendReceivedPersonCount": defineAPI<Array<string>, Array<APICore.AutoSendReceivedPersonCountVo>>("/service/trade-order/autoSendReceivedPersonCount", "POST"),
  /** 绑定小程序码sid码 */
  "POST /sid/bind": defineAPI<APICore.SidBindReq, string>("/sid/bind", "POST"),
  /** 获取当前登录用户的绑定小程序码记录 */
  "GET /sid/user-sid-bind-record": defineAPI<null, APICore.SidBindRecordVo>("/sid/user-sid-bind-record", "GET"),
  /** 主题活动详情查询-地图页(主要展示其下需显示位置的打卡点，打卡路线) */
  "POST /theme-activity/detail-map": defineAPI<APICore.ThemeActivityDetailReq, APICore.ThemeActivityDetail4MapVo>("/theme-activity/detail-map", "POST"),
  /** 主题活动详情查询 */
  "POST /theme-activity/detail": defineAPI<APICore.ThemeActivityDetailReq, APICore.ThemeActivityDetailVo>("/theme-activity/detail", "POST"),
  /** 主题活动版块详情查询 */
  "POST /theme-activity/section-detail": defineAPI<APICore.ThemeActivitySectionDetailReq, APICore.ThemeActivitySectionDetailVo>("/theme-activity/section-detail", "POST"),
  /** 主题活动板块内各活动参与进度状态查询 */
  "POST /theme-activity/participate-status": defineAPI<APICore.ThemeActivityDetailReq, Array<APICore.ThemeActivitySectionParticipateStatusVo>>("/theme-activity/participate-status", "POST"),
  /** 礼物记录订单-分页查询 */
  "POST /trade/order/page": defineAPI<APICore.TradOrderPageQueryReq, APICore.TradeOrderVoPagedData>("/trade/order/page", "POST"),
  /** 商品订单记录详情-根据订单id查询 */
  "GET /trade/order/detailById": defineAPI<{ tradeOrderId?: string }, APICore.TradeOrderDetailVo>("/trade/order/detailById", "GET"),
  /** PC-礼物记录订单-分页查询 */
  "POST /trade/order/pc/page": defineAPI<APICore.TradOrderPagePCQueryReq, APICore.TradeOrderPCVoPagedData>("/trade/order/pc/page", "POST"),
  /** PC-商品订单记录详情-根据订单id查询 */
  "GET /trade/order/pc/detailById": defineAPI<{ tradeOrderId?: string }, APICore.TradeOrderDetailPCVo>("/trade/order/pc/detailById", "GET"),
  /** 商品订单记录详情-多条件查询 */
  "POST /trade/order/detailByParam": defineAPI<APICore.TradeOrderQueryReq, Array<APICore.TradeOrderDetailVo>>("/trade/order/detailByParam", "POST"),
  /** 商品订单记录条数-根据活动id查询 */
  "GET /trade/order/countRecord": defineAPI<{ activityId?: number }, number>("/trade/order/countRecord", "GET"),
  /** 定向转赠分页查询 */
  "POST /trade/order/direct/transfer/list": defineAPI<APICore.DirectTransferPageReq, APICore.DirectTransferVoPagedData>("/trade/order/direct/transfer/list", "POST"),
  /** 按指定Token ID 查询流转记录用 */
  "POST /trade/order/token/list": defineAPI<APICore.TradeOrderTokenPageReq, APICore.TokenExchangePageVoPagedData>("/trade/order/token/list", "POST"),
  /** 藏品-直接转增 */
  "POST /transfer/product/direct-transfer": defineAPI<APICore.ProductDirectTransferRequest, boolean>("/transfer/product/direct-transfer", "POST"),
  /** 权益-直接转增 */
  "POST /transfer/benefit/direct-transfer": defineAPI<APICore.CouponDirectTransferRequest, boolean>("/transfer/benefit/direct-transfer", "POST"),
  /** 商品-扫码分享 */
  "POST /transfer/product/scan-transfer": defineAPI<APICore.ProductScanTransferRequest, APICore.ScanProductTransferResponse>("/transfer/product/scan-transfer", "POST"),
  /** 权益-扫码分享 */
  "POST /transfer/coupon/scan-transfer": defineAPI<APICore.CouponScanTransferRequest, APICore.ScanCouponTransferResponse>("/transfer/coupon/scan-transfer", "POST"),
  /** 根据当前登录用户获取核销订单列表 */
  "POST /merchant/order/list": defineAPI<APICore.VerifyOrderListReq, APICore.VerifyOrderVoPagedData>("/merchant/order/list", "POST"),
  /** 搜索核销订单 */
  "POST /merchant/order/search": defineAPI<APICore.VerifyOrderSearchReq, APICore.VerifyOrderVoPagedData>("/merchant/order/search", "POST"),
  /** 获取核销订单详情 */
  "POST /merchant/order/detail": defineAPI<APICore.VerifyOrderDetailReq, APICore.VerifyOrderDetailVo>("/merchant/order/detail", "POST"),
  /**  */
  "POST /merchant/order/statistics": defineAPI<null, APICore.VerifyOrderStatisticsVo>("/merchant/order/statistics", "POST"),
  /** 不需要核销人-核销 */
  "POST /merchant/verify-product/scan-without-merchant": defineAPI<APICore.BenefitVerificationRequest, APICore.OrderCreateVo>("/merchant/verify-product/scan-without-merchant", "POST"),
  /** 需要核销人 */
  "POST /merchant/verify-product/scan": defineAPI<APICore.BenefitVerificationRequest, APICore.OrderCreateVo>("/merchant/verify-product/scan", "POST"),
  /** 通过 Token Id 获取核销权益信息 */
  "POST /merchant/verify-product/get-by-token-id": defineAPI<APICore.GetVerifyProductByTokenIdReq, APICore.VerifyProductVo>("/merchant/verify-product/get-by-token-id", "POST"),
  /** 通过 Token Id 获取核销权益信息 */
  "POST /merchant/verify-product/get-by-token-id-with-auth": defineAPI<APICore.GetVerifyProductByTokenIdReq, APICore.VerifyProductVo>("/merchant/verify-product/get-by-token-id-with-auth", "POST"),
  /** 核销订单记录查询 */
  "POST /merchant/verify-product/verify-detail-byTokenId": defineAPI<APICore.MerchantVerifyDetailReq, APICore.AdminVerifyTradeOrderDetailAnonymousVo>("/merchant/verify-product/verify-detail-byTokenId", "POST"),
  /** 首页——数字 */
  "POST /view-goods/page": defineAPI<APICore.ViewGoodsPageReq, APICore.ViewGoodsVoPagedData>("/view-goods/page", "POST"),
  /** 世博权益核销后回调核销本地权益接口 */
  "POST /world-expo/coupon-verify/verify": defineAPI<APICore.WorldExpoCouponVerifyReq, boolean>("/world-expo/coupon-verify/verify", "POST")
}

type APIMap = typeof apiMap;
type APIPaths = keyof APIMap;

export default function callAPI<Path extends APIPaths>(path: Path, params?: Parameters<APIMap[Path]>[0], option?: Parameters<APIMap[Path]>[1]): ReturnType<APIMap[Path]> {
  // @ts-ignore
  return apiMap[path](params, option);
}

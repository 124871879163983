// import { request } from "@@/plugin-request/request";
// import { API_VERSION, SERVER } from "@/services";
import server from "@/server";

export async function getUserSuccessApplyCount(airdropId: string) {
  return server.core("GET /remind/apply/get-user-success-apply", { airdropId })
  // return request<number>(
  //   `/${ SERVER.product }/${ API_VERSION }/apply/getUserSuccessApply`,
  //   {
  //     method: "GET",
  //     params: { airdropId }
  //   }
  // );
}

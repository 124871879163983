import { request } from '@@/plugin-request/request';
import { API_VERSION, SERVER } from '@/services';

type Params = {
  prodName: string;
  refAirDropMgtId: number | string;
  refProductDefId: number | string;
};
//  根据用户id和空投id查询申领信息
export function signInAirdrop(params: Params) {
  // todo 更换为core接口
  return request(`/${SERVER.product}/${API_VERSION}/apply/save`, {
    method: 'POST',
    data: params,
    useAuth: true,
  });
}
